import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import Image from "gatsby-image";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import { StaticImage } from "gatsby-plugin-image";

const Donation = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);
  const DonationContainer = styled.div`
    display: flex;
    margin-bottom: ${rhythm(2.5)};
    flex-direction: column;

    @media (max-width: 768px) {
      margin-bottom: ${rhythm(1)};
    }
  `;
  // const StyledImage = styled(Image)`
  //   margin: 0;
  //   width: 200px;
  //   flex-shrink: 0;
  // `;

  const { author } = data.site.siteMetadata;

  return (
    <DonationContainer>
      <p>It feels weird asking you to buy me a coffee. But here it is.</p>
      <p>
        <a
          className="pink"
          href="https://paypal.me/shimmichristo?locale.x=en_US"
        >
          PayPal - shimmiChristo
        </a>
      </p>
      <p>
        <StaticImage
          src="../../content/assets/paypal.jpg"
          alt="paypal donate qr code"
          placeholder="blurred"
          layout="fixed"
          width={300}
        />
        <StaticImage
          src="../../content/assets/venmo.jpg"
          alt="venmo donate qr code"
          placeholder="blurred"
          layout="fixed"
          width={300}
        />
        <StaticImage
          src="../../content/assets/cashapp.png"
          alt="cashapp donate qr code"
          placeholder="blurred"
          layout="fixed"
          width={300}
        />
      </p>
    </DonationContainer>
  );
};

export default Donation;
